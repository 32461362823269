<template>
  <div class="rank-page">
    <div class="rank-content">
      <div class="header">
        <span class="title">本市影院本年度票房排行榜</span>
        <div class="update-time">最后更新：{{ updateTime }}</div>
      </div>

      <div class="rank-list" v-if="dataList.length > 0">
        <div class="list-header">
          <div class="col rank">名次</div>
          <div class="col name">影院</div>
          <div class="col amount">票房(万)</div>
          <div class="col amount">服务费(万)</div>
        </div>

        <div class="list-body">
          <div 
            v-for="(item, index) in dataList" 
            :key="index"
            class="list-item"
            :class="{ 'row-even': index % 2 === 1 }"
          >
            <div class="col rank">
              <div class="rank-wrapper" :class="{'top-rank': item.index <= 3}">
                <img
                  v-if="item.index === 1"
                  src="@/assets/icon/huangguan_1.svg"
                  class="rank-icon"
                  alt="第一名"
                />
                <img
                  v-else-if="item.index === 2"
                  src="@/assets/icon/huangguan_2.svg"
                  class="rank-icon"
                  alt="第二名"
                />
                <img
                  v-else-if="item.index === 3"
                  src="@/assets/icon/huangguan.svg"
                  class="rank-icon"
                  alt="第三名"
                />
                <span v-else class="rank-number">{{ item.index }}</span>
              </div>
            </div>
            <div class="col name">{{ item.cinemaName }}</div>
            <div class="col amount">{{ (item.boxOffice/10000).toFixed(2) }}</div>
            <div class="col amount">{{ (item.serviceCharge/10000).toFixed(2) }}</div>
          </div>
        </div>
      </div>

      <div class="empty-state" v-else>
        <van-empty description="暂无数据" />
      </div>

      <div class="description">
        <van-icon name="info-o" />
        <div class="desc-text">
          本页面数据自2022年1月至2022年9月10日累计票房，按照票房（不包含服务费）金额从大到小排名。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { zjApi } from '@/api/index'
import { Toast } from 'vant'

export default {
  name: 'CinemaRank',
  data() {
    return {
      cinemaId: '',
      updateTime: '2018-01-01',
      dataList: [],
    }
  },
  mounted() {
    this.cinemaId = this.$route.query.id || ''
    this.getData()
  },
  methods: {
    getData() {
      const userCode = this.$store.state.userInfo.mobile
      const query = {
        UserCode: userCode,
        CmdIndex: "101602",
        cmdArgs: [this.cinemaId]
      }
      zjApi.getPs(query).then((r) => {
        const { result: data, code, Message } = r
        if (code === 200) {
          this.dataList = data.rankDatas.map((item) => ({
            index: item.CityRank,
            cinemaName: item.CinemaName,
            boxOffice: item.BoxOfficeNoService,
            serviceCharge: item.ServiceFee,
          }))
          this.updateTime = data.UpdateDate
        } else {
          Toast(Message)
        }
      })
    },
  },
}
</script>

<style scoped lang="less">
.rank-page {
  height: 100vh;
  background: #f5f7fa;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.rank-content {
  flex: 1;
  padding: 16px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; // 增加 iOS 滚动惯性
}

.header {
  background: linear-gradient(135deg, #4facfe 0%, #00f2fe 100%);
  padding: 20px;
  border-radius: 12px;
  color: #fff;
  margin-bottom: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  position: sticky;
  top: 0;
  z-index: 2;

  .title {
    font-size: 18px;
    font-weight: 600;
    display: block;
    margin-bottom: 8px;
  }

  .update-time {
    font-size: 13px;
    opacity: 0.8;
  }
}

.rank-list {
  background: #fff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  margin-bottom: 16px;

  .list-header {
    display: grid;
    grid-template-columns: 0.6fr 2fr 1fr 1fr;
    background: #f8f9fa;
    padding: 16px 12px;
    font-weight: 500;
    color: #666;
    font-size: 14px;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .list-body {
    .list-item {
      display: grid;
      grid-template-columns: 0.6fr 2fr 1fr 1fr;
      padding: 16px 12px;
      transition: background-color 0.3s ease;
      align-items: center;
      
      &.row-even {
        background: #f8f9fa;
      }

      &:hover {
        background: #f0f7ff;
      }
    }
  }

  .col {
    text-align: center;
    font-size: 14px;
    color: #333;

    &.name {
      text-align: left;
      padding: 0 12px;
    }

    &.amount {
      color: #1989fa;
      font-weight: 500;
    }
  }
}

.rank-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  &.top-rank {
    transform: scale(1.1);
  }

  .rank-icon {
    width: 30px;
    height: 30px;
  }

  .rank-number {
    font-size: 16px;
    font-weight: 600;
    color: #666;
  }
}

.description {
  margin-top: 16px;
  padding: 12px;
  background: rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
  gap: 8px;
  
  .van-icon {
    font-size: 16px;
    color: #999;
    margin-top: 2px;
  }

  .desc-text {
    color: #999;
    font-size: 13px;
    line-height: 1.5;
  }
}

.empty-state {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  background: #fff;
  border-radius: 12px;
  margin-bottom: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);

  :deep(.van-empty) {
    padding: 32px 0;
    
    .van-empty__image {
      width: 120px;
      height: 120px;
    }

    .van-empty__description {
      margin-top: 16px;
      padding: 0;
      color: #999;
      font-size: 14px;
    }
  }
}
</style>
